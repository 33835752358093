import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-question-button',
    templateUrl: './question-button.component.html',
    styleUrls: ['./question-button.component.css'],
    standalone: true
})
export class QuestionButtonComponent {
  @Input() link: string = null;
  @Input() text: string = '?';
  @Input() size: number = 24;
  @Input() iconUrl: string = "/img/app-question-btn.svg";
  @Output() onClick : EventEmitter<any> = new EventEmitter<any>();

  onClickAction = () => {
    if(this.link) {
      window.open(this.link)
    }
    this.onClick.emit();
  }

}
